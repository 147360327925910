import GBG from '@gbg/gbgcomponentlibrary_react';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useUnlockUserMutation, useUpdateUserStatusMutation } from '../api/users';
import { TOAST_SUCCESS } from '../constants';
import { ModalContentType } from '../features/modal/config';
import { openModal } from '../features/modal/modalSlice';
interface IUserListButtonProps {
  setPasswordUser?: (value: React.SetStateAction<IUser | undefined>) => void;
  dispatch: any;
  departments?: IDepartment[];
  setMoveDepartmentUser?: React.Dispatch<React.SetStateAction<IUser | undefined>>;
}
const userListOverflowActions = ({
  setPasswordUser,
  dispatch,
  departments,
  setMoveDepartmentUser,
}: IUserListButtonProps) => {
  const intl = useIntl();
  const [updateStatus] = useUpdateUserStatusMutation();
  const [unlockUser, { isLoading }] = useUnlockUserMutation();
  const actions = useCallback(
    (item: IUser) =>
      [
        setPasswordUser && item.enabled
          ? {
              title: intl.formatMessage({ id: 'btn.setTempPassword' }),
              action: (dataItem: IUser) => {
                setPasswordUser(c => (dataItem.enabled ? dataItem : c));
              },
            }
          : null,
        {
          title: intl.formatMessage({ id: item.enabled ? 'btn.deactiveAccount' : 'btn.activeAccount' }),
          action: (dataItem: IUser) => {
            updateStatus({ id: dataItem.id ?? '', orgId: dataItem.gbgCompanyId, status: !item.enabled })
              .unwrap()
              .then(() => {
                GBG.toast(TOAST_SUCCESS);
              })
              .catch(error => dispatch(openModal({ type: ModalContentType.API_ERROR, data: error })));
          },
        },
        {
          title: intl.formatMessage({ id: 'btn.remove' }),
          danger: true,
          action: (dataItem: IUser) => {
            dispatch(
              openModal({
                type: ModalContentType.REMOVE_USER,
                data: item || dataItem,
              }),
            );
          },
        },

        setMoveDepartmentUser &&
        departments &&
        departments.filter(x => x.gbgDeptId !== item.gbgDepartmentId).length > 0 &&
        !item.gbgClientId
          ? {
              title: intl.formatMessage({ id: 'btn.MoveDepartment' }),
              action: (dataItem: IUser) => {
                setMoveDepartmentUser(dataItem);
              },
            }
          : null,
        item.account?.status === 'LOCKED'
          ? {
              title: intl.formatMessage({ id: 'btn.unlock' }),
              action: (dataItem: IUser) => {
                unlockUser({ userId: dataItem.id ?? '', orgId: dataItem.gbgCompanyId })
                  .unwrap()
                  .then((result: IUser) => {
                    if (result && result.id) GBG.toast(TOAST_SUCCESS);
                    else dispatch(openModal({ type: ModalContentType.API_ERROR, data: result }));
                  })
                  .catch(error => dispatch(openModal({ type: ModalContentType.API_ERROR, data: error })));
              },
            }
          : null,
      ].filter(Boolean),
    [departments, setMoveDepartmentUser, setPasswordUser, dispatch, intl, updateStatus, unlockUser],
  );

  return { actions, isLoading };
};

export default userListOverflowActions;
