import { adminApi } from '../app/api';

const endpoint = '/api/v1/user';
const customerAdminEndPoint = '/api/v1/organisations';

const extendedApi = adminApi.injectEndpoints({
  endpoints: build => ({
    getUsers: build.query<IPaginatedRes<IUser>, IPaginatedReqWithParams>({
      query: ({ search, limit, cursor, organisationId, departmentId }) => ({
        url: `${customerAdminEndPoint}/${organisationId}/users?departmentId=${departmentId ?? ''}&search=${
          search ?? ''
        }&cursor=${cursor ?? ''}&limit=${limit ?? ''}`,
        method: 'GET',
      }),
      providesTags: result =>
        result
          ? [
              ...result.embedded.entries.map(({ id }) => ({ type: 'Users', id } as const)),
              { type: 'Users', id: 'LIST' },
            ]
          : [{ type: 'Users', id: 'LIST' }],
    }),
    getUser: build.query<IUser, { userId: string; orgId: string }>({
      query: ({ userId, orgId }) => ({ url: `${customerAdminEndPoint}/${orgId}/users/${userId}`, method: 'GET' }),
      providesTags: result => (result ? [{ type: 'Users', id: result?.id }] : []),
    }),
    addUser: build.mutation<IUser, IUser>({
      query: data => ({ url: endpoint, method: 'POST', data }),
      invalidatesTags: [
        { type: 'Users', id: 'LIST' },
        { type: 'Licences', id: 'LIST' },
      ],
    }),
    updateUser: build.mutation<IUser, IUser>({
      query: data => ({ url: endpoint, method: 'PUT', data }),
      invalidatesTags: result =>
        result
          ? [
              { type: 'Users', id: result?.id },
              { type: 'UserRoles', id: 'LIST' },
              { type: 'Licences', id: 'LIST' },
            ]
          : [],
    }),

    deleteUser: build.mutation<string, { userId: string; orgId: string }>({
      query: ({ orgId, userId }) => ({ url: `${customerAdminEndPoint}/${orgId}/users/${userId}`, method: 'DELETE' }),
      invalidatesTags: id =>
        id
          ? [
              { type: 'Users', id },
              { type: 'Licences', id: 'LIST' },
            ]
          : [],
    }),
    updateUserStatus: build.mutation<IUser, { id: string; orgId: string; status: boolean }>({
      query: ({ id, orgId, status }) => ({
        url: `${customerAdminEndPoint}/${orgId}/users/${id}/status`,
        method: 'PUT',
        data: { id, status },
      }),
      invalidatesTags: result =>
        result
          ? [
              { type: 'Users', id: result.id },
              { type: 'Licences', id: 'LIST' },
            ]
          : [],
    }),
    getClientUsers: build.query<IPaginatedRes<IUser>, { clientId: string; search?: string }>({
      query: ({ clientId, search }) => ({
        url: `${endpoint}/ClientUser?clientId=${clientId}&search=${search ?? ''}`,
        method: 'GET',
      }),
      providesTags: result =>
        result
          ? [
              ...result.embedded.entries.map(({ id }) => ({ type: 'Users', id } as const)),
              { type: 'Users', id: 'CLIENT_USER_LIST' },
            ]
          : [{ type: 'Users', id: 'CLIENT_USER_LIST' }],
    }),
    addClientUser: build.mutation<IUser, IUser>({
      query: data => ({ url: `${endpoint}/ClientUser`, method: 'POST', data }),
      invalidatesTags: [
        { type: 'Users', id: 'CLIENT_USER_LIST' },
        { type: 'Licences', id: 'LIST' },
      ],
    }),
    resetPassword: build.mutation<void, IResetPassword>({
      query: data => ({
        url: `${customerAdminEndPoint}/${data.orgId}/users/${data.userId}/password`,
        method: 'PATCH',
        data,
      }),
    }),
    unlockUser: build.mutation<IUser, { userId: string; orgId: string }>({
      query: ({ orgId, userId }) => ({
        url: `${customerAdminEndPoint}/${orgId}/users/${userId}/unlock`,
        method: 'PATCH',
      }),
      invalidatesTags: result => (result && result.id ? [{ type: 'Users', id: result.id }] : []),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useGetUserQuery,
  useDeleteUserMutation,
  useGetClientUsersQuery,
  useAddClientUserMutation,
  useResetPasswordMutation,
  useUpdateUserStatusMutation,
  useUnlockUserMutation,
} = extendedApi;
