import React, { memo, useCallback, useEffect, useState } from 'react';
import TempPasswordModal from './TempPasswordModal';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { useGetUsersQuery } from '../../../api/users';
import { PAGE_SIZE } from '../../../constants';
import Table from '../../../components/table/Table';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import { ModalContentType } from '../../../features/modal/config';
import userListOverflowActions from '../../../hooks/userListOverflowActions';
import { useGetDepartmentsQuery } from '../../../api/departments';
import ChangeUserDepartmentModal from './ChangeUserDepartmentModal';

interface IUserList {
  orgId: string;
}

const UsersList: React.FC<IUserList> = ({ orgId }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [passwordUser, setPasswordUser] = useState<IUser | undefined>();
  const [moveDepartmentUser, setMoveDepartmentUser] = useState<IUser | undefined>();
  const headers = ['fullName', 'lastSignOn.at', 'enabled', 'gbgDepartmentName'];
  const [search, setSearch] = useState<string>('');
  const [cursor, setCursor] = useState<string>('');
  const { data: allOrgDepartments } = useGetDepartmentsQuery(orgId);
  const { data, isFetching, isError, error } = useGetUsersQuery({
    organisationId: orgId,
    search,
    cursor,
    limit: PAGE_SIZE,
  });
  useEffect(() => {
    if (!isError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
    }
  }, [isError, error]);

  const onItemClicked = useCallback((item: IUser) => history.push(`/organisations/${orgId}/users/${item.id}`), [orgId]);
  const onCloseHandler = useCallback(() => setPasswordUser(undefined), []);

  const { actions, isLoading } = userListOverflowActions({
    dispatch,
    setPasswordUser,
    setMoveDepartmentUser,
    departments: allOrgDepartments,
  });

  return (
    <>
      <Table
        addUrl={`/organisations/${orgId}/users/new-user`}
        {...{ headers, data, isFetching: isFetching || isLoading, onItemClicked, actions, setSearch, setCursor }}
      ></Table>
      <TempPasswordModal user={passwordUser} onClose={onCloseHandler}></TempPasswordModal>
      <ChangeUserDepartmentModal
        user={moveDepartmentUser}
        departments={allOrgDepartments}
        dispatch={dispatch}
        setUser={setMoveDepartmentUser}
      />
    </>
  );
};

export default memo(UsersList);
